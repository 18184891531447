import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../context/AuthContext';
import './AdminDashboard.css';
import CharacterSheet from '../components/CharacterSheet';

const AdminDashboard = () => {
  const { currentUser, logout } = useAuth();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [roleRequests, setRoleRequests] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [activeTab, setActiveTab] = useState('users');
  const [searchTerm, setSearchTerm] = useState('');
  const [confirmAction, setConfirmAction] = useState(null);
  const [messageText, setMessageText] = useState('');
  const [sendingMessage, setSendingMessage] = useState(false);
  const [messageError, setMessageError] = useState('');
  const [showMessageForm, setShowMessageForm] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [showHtmlGuide, setShowHtmlGuide] = useState(false);
  const [showUserDetails, setShowUserDetails] = useState(true);
  const [userCharacters, setUserCharacters] = useState([]);
  const [selectedCharacter, setSelectedCharacter] = useState(null);
  const [jsonEditorValue, setJsonEditorValue] = useState('');
  const [isEditingJson, setIsEditingJson] = useState(false);
  const fileInputRef = useRef(null);
  const [emailInput, setEmailInput] = useState(selectedUser?.email || '');

  // Use production URL when in production, localhost for development
  const API_URL = window.location.hostname === 'localhost' 
    ? 'http://localhost:3001/api'
    : 'https://firstveil.com/api';  // Replace with your actual production API URL

  useEffect(() => {
    fetchUsers();
    fetchRoleRequests();
    fetchNotifications();

    // Set up polling for new notifications
    const notificationInterval = setInterval(() => {
      fetchNotifications(true);
    }, 60000); // Check every minute

    return () => clearInterval(notificationInterval);
  }, []);

  useEffect(() => {
    if (selectedUser) {
      setEmailInput(selectedUser.email || '');
    }
  }, [selectedUser]);

  const fetchUsers = async () => {
    setLoading(true);
    setError('');
    
    try {
      console.log('Fetching users from:', `${API_URL}/admin/users`);
      const response = await fetch(`${API_URL}/admin/users`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || `Server responded with status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Users fetched successfully:', data);
      setUsers(data);
    } catch (err) {
      console.error('Error fetching users:', err);
      setError(`Error fetching users: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  const fetchRoleRequests = async () => {
    try {
      console.log('Fetching role requests from:', `${API_URL}/admin/role-requests`);
      const response = await fetch(`${API_URL}/admin/role-requests`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('Error response:', errorData);
        return; // Don't set error state here to avoid overriding user fetch errors
      }
      
      const data = await response.json();
      console.log('Role requests fetched successfully:', data);
      setRoleRequests(data);
    } catch (err) {
      console.error('Error fetching role requests:', err);
      // Don't set error state here to avoid overriding user fetch errors
    }
  };

  const fetchNotifications = async (silent = false) => {
    try {
      if (!silent) setLoading(true);
      
      const response = await fetch(`${API_URL}/admin/notifications`, {
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('Error response:', errorData);
        return;
      }
      
      const data = await response.json();
      console.log('Notifications fetched successfully:', data);
      
      // Count unread notifications
      const unread = data.filter(notification => !notification.read).length;
      setUnreadNotifications(unread);
      
      setNotifications(data);
    } catch (err) {
      console.error('Error fetching notifications:', err);
    } finally {
      if (!silent) setLoading(false);
    }
  };

  const markNotificationAsRead = async (notificationId) => {
    try {
      const response = await fetch(`${API_URL}/admin/notifications/${notificationId}/read`, {
        method: 'PUT',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('Error response:', errorData);
        return;
      }
      
      // Update local state
      setNotifications(notifications.map(notification => 
        notification.id === notificationId ? { ...notification, read: true } : notification
      ));
      
      // Update unread count
      setUnreadNotifications(prev => Math.max(0, prev - 1));
    } catch (err) {
      console.error('Error marking notification as read:', err);
    }
  };

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setShowMessageForm(false);
    setShowUserDetails(true);
    fetchUserCharacters(user.id);
  };

  const handleBackToList = () => {
    setShowUserDetails(false);
  };

  const handleRoleChange = async (userId, newRole) => {
    try {
      const response = await fetch(`${API_URL}/admin/users/${userId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: JSON.stringify({ role: newRole })
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to update user role');
      }
      
      // Update local state
      setUsers(users.map(user => 
        user.id === userId ? { ...user, role: newRole } : user
      ));
      
      if (selectedUser && selectedUser.id === userId) {
        setSelectedUser({ ...selectedUser, role: newRole });
      }
      
      // If this was a role request, refresh the requests
      fetchRoleRequests();
      setError(''); // Clear any previous errors
    } catch (err) {
      console.error('Error updating user role:', err);
      setError(`Error updating user role: ${err.message}`);
    }
  };

  const handlePasswordReset = async (userId) => {
    setConfirmAction({
      type: 'resetPassword',
      userId,
      message: 'Are you sure you want to reset this user\'s password?',
      confirmText: 'Reset Password',
      action: async () => {
        try {
          const response = await fetch(`${API_URL}/admin/users/${userId}/reset-password`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'x-auth-token': localStorage.getItem('auth_token')
            },
            body: JSON.stringify({ newPassword: 'tempPassword123' }) // Default temporary password
          });
          
          if (!response.ok) {
            const errorData = await response.json().catch(() => ({}));
            throw new Error(errorData.message || 'Failed to reset password');
          }
          
          setError('');
          alert('Password has been reset to: tempPassword123');
        } catch (err) {
          console.error('Error resetting password:', err);
          setError(`Error resetting password: ${err.message}`);
        } finally {
          setConfirmAction(null);
        }
      }
    });
  };

  const handleDeleteUser = async (userId) => {
    setConfirmAction({
      type: 'deleteUser',
      userId,
      message: 'Are you sure you want to delete this user? This action cannot be undone.',
      confirmText: 'Delete User',
      action: async () => {
        try {
          const response = await fetch(`${API_URL}/admin/users/${userId}`, {
            method: 'DELETE',
            headers: {
              'x-auth-token': localStorage.getItem('auth_token')
            }
          });
          
          if (!response.ok) {
            const errorData = await response.json().catch(() => ({}));
            throw new Error(errorData.message || 'Failed to delete user');
          }
          
          // Update local state
          setUsers(users.filter(user => user.id !== userId));
          
          if (selectedUser && selectedUser.id === userId) {
            setSelectedUser(null);
          }
          
          setError('');
        } catch (err) {
          console.error('Error deleting user:', err);
          setError(`Error deleting user: ${err.message}`);
        } finally {
          setConfirmAction(null);
        }
      }
    });
  };

  const handleApproveRequest = async (requestId, userId) => {
    try {
      // First update the user's role
      await handleRoleChange(userId, 'adventurer');
      
      // Then mark the request as approved
      const response = await fetch(`${API_URL}/admin/role-requests/${requestId}/approve`, {
        method: 'PUT',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to approve request');
      }
      
      // Update local state
      setRoleRequests(roleRequests.filter(req => req.id !== requestId));
      setError(''); // Clear any previous errors
    } catch (err) {
      console.error('Error approving request:', err);
      setError(`Error approving request: ${err.message}`);
    }
  };

  const handleDenyRequest = async (requestId) => {
    try {
      const response = await fetch(`${API_URL}/admin/role-requests/${requestId}/deny`, {
        method: 'PUT',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to deny request');
      }
      
      // Update local state
      setRoleRequests(roleRequests.filter(req => req.id !== requestId));
      setError(''); // Clear any previous errors
    } catch (err) {
      console.error('Error denying request:', err);
      setError(`Error denying request: ${err.message}`);
    }
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    
    if (!selectedUser) {
      setMessageError('No user selected');
      return;
    }
    
    if (!messageText.trim()) {
      setMessageError('Message cannot be empty');
      return;
    }
    
    setSendingMessage(true);
    setMessageError('');
    
    try {
      // Log the request details for debugging
      console.log('Sending message to user ID:', selectedUser.id);
      console.log('Message content:', messageText);
      
      // Try the endpoint with user ID in the path
      const endpoint = `${API_URL}/admin/users/${selectedUser.id}/message`;
      console.log('Using API endpoint:', endpoint);
      
      const response = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: JSON.stringify({
          message: messageText,
          fromAdmin: true
        })
      });
      
      // Log the response for debugging
      console.log('Response status:', response.status);
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        console.error('Error response data:', errorData);
        throw new Error(errorData.message || `Failed to send message: ${response.status}`);
      }
      
      // Clear the message form
      setMessageText('');
      setShowMessageForm(false);
      alert(`Message sent to ${selectedUser.displayName}`);
    } catch (err) {
      console.error('Error sending message:', err);
      setMessageError(`Error sending message: ${err.message}`);
      
      // Show a more detailed error message to help with debugging
      alert(`Failed to send message. Error: ${err.message}\nPlease check the console for more details.`);
    } finally {
      setSendingMessage(false);
    }
  };

  const handleRetry = () => {
    setError('');
    setActiveTab('users');
    fetchUsers();
    fetchRoleRequests();
    fetchNotifications();
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };
  
  // Function to handle inserting HTML formatting tags
  const insertFormatting = (type) => {
    // Get textarea element
    const textarea = document.querySelector('.message-form textarea');
    if (!textarea) return;
    
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const selectedText = messageText.substring(start, end);
    let replacement = '';
    
    // Insert appropriate HTML based on the formatting type
    switch (type) {
      case 'bold':
        replacement = `<strong>${selectedText || 'bold text'}</strong>`;
        break;
      case 'italic':
        replacement = `<em>${selectedText || 'italic text'}</em>`;
        break;
      case 'heading':
        replacement = `<h3>${selectedText || 'Heading'}</h3>`;
        break;
      case 'paragraph':
        replacement = `<p>${selectedText || 'New paragraph'}</p>`;
        break;
      case 'blockquote':
        replacement = `<blockquote>${selectedText || 'Quoted text'}</blockquote>`;
        break;
      case 'list':
        replacement = `<ul>\n  <li>${selectedText || 'List item'}</li>\n  <li>Another item</li>\n</ul>`;
        break;
      default:
        return;
    }
    
    // Insert the HTML at the cursor position or replace selected text
    const newText = messageText.substring(0, start) + replacement + messageText.substring(end);
    setMessageText(newText);
    
    // Focus back on textarea after inserting
    setTimeout(() => {
      textarea.focus();
      textarea.setSelectionRange(start + replacement.length, start + replacement.length);
    }, 0);
  };

  // Filter users based on search term
  const filteredUsers = users.filter(user => 
    user.displayName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.role?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleLogout = () => {
    logout();
  };

  const fetchUserCharacters = async (userId) => {
    try {
      // Check local storage first - character data may be there
      const localStorageKey = `character_${userId}`;
      const localData = localStorage.getItem(localStorageKey);
      
      if (localData) {
        try {
          const parsedData = JSON.parse(localData);
          console.log('Found character in localStorage:', parsedData);
          setUserCharacters([parsedData]);
          return;
        } catch (e) {
          console.warn('Failed to parse localStorage data', e);
        }
      }
      
      // If not in localStorage, try the API
      console.log(`Trying API for user ID: ${userId}`);
      const response = await fetch(`${API_URL}/api/characters`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      if (response.ok) {
        const data = await response.json();
        console.log('All characters from API:', data);
        
        // Filter for the specific user's characters
        const userCharacters = Array.isArray(data) 
          ? data.filter(char => char.userId === userId || char.user === userId)
          : [];
          
        console.log('Filtered characters for user:', userCharacters);
        setUserCharacters(userCharacters);
      } else {
        console.error('API error:', response.status);
        setUserCharacters([]);
      }
    } catch (error) {
      console.error('Error fetching characters:', error);
      setUserCharacters([]);
    }
  };

  const uploadJsonFile = async (file) => {
    if (!file || !selectedUser) return;
    
    // Validate that it's a JSON file
    if (file.type !== 'application/json' && !file.name.endsWith('.json')) {
      alert('Please upload a valid JSON file');
      return;
    }
    
    try {
      const reader = new FileReader();
      reader.onload = async (event) => {
        try {
          // Parse and validate JSON
          const characterData = JSON.parse(event.target.result);
          
          // Check if it's a valid character JSON
          if (!characterData || typeof characterData !== 'object') {
            alert('Invalid character data format');
            return;
          }
          
          // Store in localStorage for the selected user
          const storageKey = `character_${selectedUser.id}`;
          localStorage.setItem(storageKey, JSON.stringify(characterData));
          
          // Update the UI
          setUserCharacters([characterData]);
          alert('Character data uploaded successfully');
        } catch (e) {
          alert('Invalid JSON format: ' + e.message);
        }
      };
      reader.readAsText(file);
    } catch (error) {
      console.error('Error reading file:', error);
      alert('Failed to read file');
    }
  };

  const handleViewCharacter = async (characterId) => {
    try {
      const response = await fetch(`${API_URL}/api/characters/${characterId}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      if (!response.ok) throw new Error('Failed to fetch character data');
      
      const data = await response.json();
      setSelectedCharacter(data);
      setJsonEditorValue(JSON.stringify(data, null, 2));
    } catch (error) {
      console.error('Error fetching character data:', error);
      alert('Failed to load character data');
    }
  };

  const handleEditCharacter = () => {
    setIsEditingJson(true);
  };

  const handleSaveCharacterJson = async () => {
    try {
      // Validate JSON format
      const parsedJson = JSON.parse(jsonEditorValue);
      
      const response = await fetch(`${API_URL}/api/characters/${selectedCharacter.id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(parsedJson)
      });
      
      if (!response.ok) throw new Error('Failed to update character data');
      
      setIsEditingJson(false);
      fetchUserCharacters(selectedUser.id);
      alert('Character data updated successfully');
    } catch (error) {
      console.error('Error updating character data:', error);
      alert('Invalid JSON format or update failed: ' + error.message);
    }
  };

  const handleCancelEdit = () => {
    setJsonEditorValue(JSON.stringify(selectedCharacter, null, 2));
    setIsEditingJson(false);
  };

  const handleDeleteCharacter = async (characterId) => {
    if (!window.confirm('Are you sure you want to delete this character?')) return;
    
    try {
      const response = await fetch(`${API_URL}/api/characters/${characterId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      if (!response.ok) throw new Error('Failed to delete character');
      
      setSelectedCharacter(null);
      fetchUserCharacters(selectedUser.id);
      alert('Character deleted successfully');
    } catch (error) {
      console.error('Error deleting character:', error);
      alert('Failed to delete character: ' + error.message);
    }
  };

  const checkLocalStorageForEmail = () => {
    if (!emailInput) return;
    
    // Try various localStorage keys
    const possibleKeys = [
      `character_${selectedUser?.id || ''}`,
      `character_${emailInput}`,
      `characterCache_${emailInput}`,
      `characterData_${emailInput}`,
      emailInput
    ];
    
    let found = false;
    for (const key of possibleKeys) {
      const data = localStorage.getItem(key);
      if (data) {
        try {
          const parsedData = JSON.parse(data);
          setUserCharacters([parsedData]);
          found = true;
          alert(`Found character data in localStorage for ${emailInput}`);
          break;
        } catch (e) {
          console.warn(`Invalid JSON in key ${key}`);
        }
      }
    }
    
    if (!found) {
      alert(`No character data found in localStorage for ${emailInput}`);
    }
  };

  // Add proper React-based debug function
  const debugCheckAllStorage = () => {
    if (!selectedUser) return;
    
    // Make a safe copy of all character-related localStorage data
    const characterDataFound = [];
    
    // Check all localStorage keys for any character data
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key && (key.includes('character') || key.includes(selectedUser.email) || key.includes(selectedUser.id))) {
        try {
          const data = JSON.parse(localStorage.getItem(key));
          characterDataFound.push({ key, data });
          console.log(`Found potential character data in key: ${key}`, data);
        } catch (e) {
          console.log(`Key ${key} contains invalid JSON`);
        }
      }
    }
    
    // Show results in a safer way
    if (characterDataFound.length > 0) {
      console.log('All character data found:', characterDataFound);
      alert(`Found ${characterDataFound.length} character data entries in localStorage. Check browser console for details.`);
    } else {
      alert('No character data found in localStorage.');
    }
  };

  return (
    <div className="admin-dashboard">
      {/* Header with logout button */}
      <div className="dashboard-header">
        <h1>First Veil</h1>
        <button 
          className="logout-button"
          onClick={handleLogout}
        >
          Logout
        </button>
      </div>

      <div className="dashboard-tabs">
        <button 
          className={`tab-button ${activeTab === 'users' ? 'active' : ''}`}
          onClick={() => setActiveTab('users')}
        >
          User Management
        </button>
        <button 
          className={`tab-button ${activeTab === 'requests' ? 'active' : ''}`}
          onClick={() => setActiveTab('requests')}
        >
          Role Requests {roleRequests.length > 0 && <span className="request-badge">{roleRequests.length}</span>}
        </button>
      </div>
      
      {error && (
        <div className="admin-error">
          <p>{error}</p>
          <button className="retry-button" onClick={handleRetry}>Retry</button>
        </div>
      )}
      
      <div className="tab-content">
        {activeTab === 'users' && (
          <div className="users-tab">
            <div className={`users-list-container ${showUserDetails && selectedUser ? 'mobile-hide' : ''}`}>
              <div className="search-bar">
                <input
                  type="text"
                  placeholder="Search users..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              
              {loading ? (
                <div className="loading-users">
                  <div className="loading-spinner"></div>
                  <p>Loading users...</p>
                </div>
              ) : (
                <div className="users-list">
                  {filteredUsers.length > 0 ? (
                    filteredUsers.map(user => (
                      <div 
                        key={user.id} 
                        className={`user-item ${selectedUser && selectedUser.id === user.id ? 'selected' : ''}`}
                        onClick={() => handleUserSelect(user)}
                      >
                        <div className="user-avatar">
                          {user.avatar ? (
                            <img src={user.avatar} alt={user.displayName} />
                          ) : (
                            <div className="avatar-placeholder">
                              {user.displayName?.charAt(0).toUpperCase() || 'U'}
                            </div>
                          )}
                        </div>
                        <div className="user-info">
                          <div className="user-name">{user.displayName}</div>
                          <div className="user-email">{user.email}</div>
                        </div>
                        <div className={`user-role role-${user.role}`}>
                          {user.role}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-users">No users found</div>
                  )}
                </div>
              )}
            </div>
            
            <div className={`user-details ${!showUserDetails || !selectedUser ? 'mobile-hide' : ''}`}>
              {selectedUser ? (
                <>
                  <div className="mobile-back-button" onClick={handleBackToList}>
                    ← Back to Users
                  </div>
                  
                  <h3>{selectedUser.displayName}</h3>
                  <div className="detail-item">
                    <span className="detail-label">ID:</span>
                    <span className="detail-value">{selectedUser.id}</span>
                  </div>
                  <div className="detail-item">
                    <span className="detail-label">Name:</span>
                    <span className="detail-value">{selectedUser.displayName}</span>
                  </div>
                  <div className="detail-item">
                    <span className="detail-label">Email:</span>
                    <span className="detail-value">{selectedUser.email}</span>
                  </div>
                  <div className="detail-item">
                    <span className="detail-label">Role:</span>
                    <div className="role-selector">
                      <select 
                        value={selectedUser.role}
                        onChange={(e) => handleRoleChange(selectedUser.id, e.target.value)}
                      >
                        <option value="commoner">Commoner</option>
                        <option value="adventurer">Adventurer</option>
                        <option value="admin">Admin</option>
                      </select>
                    </div>
                  </div>
                  <div className="detail-item">
                    <span className="detail-label">Created:</span>
                    <span className="detail-value">{formatDate(selectedUser.createdAt)}</span>
                  </div>
                  
                  <div className="user-actions">
                    <button 
                      className="action-button reset-password"
                      onClick={() => handlePasswordReset(selectedUser.id)}
                    >
                      Reset Password
                    </button>
                    <button 
                      className="action-button delete-user"
                      onClick={() => handleDeleteUser(selectedUser.id)}
                    >
                      Delete User
                    </button>
                  </div>
                  
                  <div className="message-section">
                    {showMessageForm ? (
                      <form onSubmit={handleSendMessage} className="message-form">
                        <h4>Send Message to {selectedUser.displayName}</h4>
                        {messageError && <div className="message-error">{messageError}</div>}
                        
                        <textarea
                          value={messageText}
                          onChange={(e) => setMessageText(e.target.value)}
                          placeholder="Enter your message here... HTML formatting is supported."
                          rows={8}
                          required
                        />
                        <div className="message-preview">
                          <h5>Preview:</h5>
                          <div 
                            className="preview-content"
                            dangerouslySetInnerHTML={{ __html: messageText }}
                          />
                        </div>
                        <div className="message-actions">
                          <button 
                            type="submit" 
                            className="send-message-btn"
                            disabled={sendingMessage}
                          >
                            {sendingMessage ? 'Sending...' : 'Send Message'}
                          </button>
                          <button 
                            type="button" 
                            className="cancel-message-btn"
                            onClick={() => setShowMessageForm(false)}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    ) : (
                      <button 
                        className="action-button message-user"
                        onClick={() => setShowMessageForm(true)}
                      >
                        Send Message
                      </button>
                    )}
                  </div>
                </>
              ) : (
                <div className="no-selection">
                  <p>Select a user to view details</p>
                </div>
              )}
            </div>
          </div>
        )}
        
        {activeTab === 'requests' && (
          <div className="requests-tab">
            <h3>Adventurer Role Requests</h3>
            
            {loading ? (
              <div className="loading-requests">
                <div className="loading-spinner"></div>
                <p>Loading requests...</p>
              </div>
            ) : roleRequests.length > 0 ? (
              <div className="requests-list">
                {roleRequests.map(request => (
                  <div key={request.id} className="request-item">
                    <div className="request-header">
                      <div className="requester-info">
                        <span className="requester-name">{request.userName}</span>
                        <span className="requester-email">{request.userEmail}</span>
                      </div>
                      <div className="request-date">{formatDate(request.createdAt)}</div>
                    </div>
                    
                    <div className="request-reason">
                      <h4>Reason for Request:</h4>
                      <p>{request.reason}</p>
                    </div>
                    
                    <div className="request-actions">
                      <button 
                        className="action-button approve"
                        onClick={() => handleApproveRequest(request.id, request.userId)}
                      >
                        Approve
                      </button>
                      <button 
                        className="action-button deny"
                        onClick={() => handleDenyRequest(request.id)}
                      >
                        Deny
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="no-requests">
                <p>No pending role requests</p>
              </div>
            )}
          </div>
        )}
      </div>
      
      {confirmAction && (
        <div className="confirm-modal">
          <div className="confirm-content">
            <h3>Confirm Action</h3>
            <p>{confirmAction.message}</p>
            <div className="confirm-buttons">
              <button 
                className="confirm-button"
                onClick={confirmAction.action}
              >
                {confirmAction.confirmText}
              </button>
              <button 
                className="cancel-button"
                onClick={() => setConfirmAction(null)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {selectedUser && showUserDetails && (
        <div className="user-details">
          <div className="character-management-section">
            <h3>Character Data Management for {selectedUser.email}</h3>
            
            <div className="debug-info">
              <h4>Debug Information</h4>
              <p>User ID: {selectedUser.id}</p>
              <p>Email: {selectedUser.email}</p>
              <p>Characters found: {userCharacters.length}</p>
              <div className="debug-actions">
                <button 
                  className="debug-button"
                  onClick={() => fetchUserCharacters(selectedUser.id)}
                >
                  Refresh Characters
                </button>
                <button 
                  className="debug-button"
                  onClick={debugCheckAllStorage}
                >
                  Check localStorage
                </button>
              </div>
            </div>
            
            <div className="character-list">
              <h4>User Characters</h4>
              {userCharacters.length === 0 ? (
                <div className="no-characters">
                  <p>No characters found for this user</p>
                  <p className="note">Characters might be stored locally in the browser.</p>
                </div>
              ) : (
                <ul>
                  {userCharacters.map((character, index) => (
                    <li key={character._id || character.id || index} className="character-item">
                      <span className="character-name">
                        {character.name || character.system?.name || 
                         character.system?.description?.name || 
                         `Character ${index + 1}`}
                      </span>
                      <div className="character-actions">
                        <button 
                          className="action-button view-json-btn"
                          onClick={() => {
                            setSelectedCharacter(character);
                            setJsonEditorValue(JSON.stringify(character, null, 2));
                          }}
                        >
                          View
                        </button>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            
            {/* Show import from localStorage button */}
            <div className="local-import-section">
              <h4>Import User's Character from localStorage</h4>
              <p>If the user has a character stored in their browser, enter their email:</p>
              <input 
                type="text" 
                placeholder="user@example.com" 
                className="email-input"
                value={emailInput}
                onChange={(e) => setEmailInput(e.target.value)}
              />
              <button 
                className="action-button local-import-btn"
                onClick={checkLocalStorageForEmail}
              >
                Find in localStorage
              </button>
            </div>

            {/* Add Upload Character section after Character List */}
            <div className="upload-character">
              <h4>Upload Character JSON</h4>
              <input 
                type="file" 
                ref={fileInputRef}
                onChange={(e) => e.target.files?.length && uploadJsonFile(e.target.files[0])} 
                accept=".json"
                className="file-input"
                style={{ display: 'none' }}
              />
              <button 
                className="action-button upload-json-btn"
                onClick={() => fileInputRef.current?.click()}
              >
                Choose JSON File
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Add CharacterSheet Section */}
      {selectedCharacter && (
        <div className="character-editor-container">
          <h4>
            Character Sheet: {selectedCharacter.name || selectedCharacter.system?.name || 'Character'}
            <div className="editor-actions">
              <button 
                className="action-button close-json-btn"
                onClick={() => {
                  setSelectedCharacter(null);
                  setIsEditingJson(false);
                }}
              >
                Close
              </button>
            </div>
          </h4>
          
          <div className="character-sheet-wrapper">
            <CharacterSheet 
              userId={selectedUser?.id}
              characterData={selectedCharacter}
              onSave={(updatedData) => {
                // Update localStorage with the new character data
                if (selectedUser && selectedCharacter) {
                  const storageKey = `character_${selectedUser.id}`;
                  localStorage.setItem(storageKey, JSON.stringify(updatedData));
                  
                  // Update in memory
                  setSelectedCharacter(updatedData);
                  
                  // Update character list
                  fetchUserCharacters(selectedUser.id);
                  
                  alert('Character data saved successfully');
                }
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminDashboard; 